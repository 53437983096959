import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import avatar1 from "../assets/img/userport.png";
import avatar2 from "../assets/img/avatars/2.png";
import avatar3 from "../assets/img/avatars/3.png";
import avatar4 from "../assets/img/avatars/4.png";
import avatar5 from "../assets/img/avatars/5.png";
import { AuthgetApi } from "../axiosConfig/promise";
import { useQuery } from "@tanstack/react-query";
import { Image_endpoint } from "../../local";
export default function Dashboard() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const url = `dashBoard`;

  const {
    data: userinfo,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["userListing", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            src={
              row?.profilePic == null
                ? avatar1
                : Image_endpoint + row.profilePic
            }
          />
          {row.fullName}
        </>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => row.email,
    },
    {
      name: "Phone No.",
      selector: (row) => row.phoneNumber,
      sortable: true,
      cell: (row) => `+${row.countryCode}${row.phoneNumber}`,
    },
    {
      name: "Status",
      selector: (row) => row.accountStatus,
      sortable: true,
      cell: (row) => (
        <span className={row.accountStatus + " badge"}>
          {row.accountStatus}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnview" to={`/Viewuser?id=${row?._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data, setData] = useState([
    {
      id: 1,
      name: "Maria James",
      imgurl: avatar1,
      email: "maris@gmail.com",
      phone: "0123-21-323",
      status: "Active",
    },
    {
      id: 2,
      name: "Johnson Charles",
      imgurl: avatar2,
      email: "johson@gmail.com",
      phone: "0123-21-323",
      status: "Inactive",
    },
    {
      id: 3,
      name: "David Smith",
      imgurl: avatar3,
      email: "david@gmail.com",
      phone: "0123-21-323",
      status: "Active",
    },
    {
      id: 4,
      name: "Steve Jones",
      imgurl: avatar4,
      email: "steve@gmail.com",
      phone: "0123-21-323",
      status: "Inactive",
    },
    {
      id: 5,
      name: "James Vince",
      imgurl: avatar5,
      email: "jamese@gmail.com",
      phone: "0123-21-323",
      status: "Active",
    },
  ]);

  // Filter data based on search text
  const filteredData = userinfo?.data?.list?.filter(
    (item) =>
      item.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.phoneNumber.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon">
              <Icon icon="tabler:users" />
            </span>
            <div className="stats-cont">
              <h4>Total Users</h4>
              <p>{userinfo?.data?.totalUser ?? 0}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon statsblue">
              {" "}
              <Icon icon="ion:business-outline" />
            </span>
            <div className="stats-cont">
              <h4>Total Business</h4>
              <p>{userinfo?.data?.totalBusiness ?? 0}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="stats">
            <span className="statsicon statsgreen">
              <Icon icon="mingcute:notification-line" />
            </span>
            <div className="stats-cont">
              <h4>Notifications</h4>
              <p>{userinfo?.data?.totalNotification ?? 0}</p>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-3">
              <h5>Recent Users</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                // pagination
                paginationPerPage={5}
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
