import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Avatar from "../assets/img/default.jpg";
import Select, { components } from "react-select";
import GoogleMapReact from "google-map-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthgetApi, authFormdataPost } from "../axiosConfig/promise";
import { GOOGLE_API_KEY } from "../../local";
import businessValidationSchema from "../formValidation/addBusinessSchema";
import toast, { Toaster } from "react-hot-toast";
import { ThreeCircles } from "react-loader-spinner";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const subcategory = [
  { value: "Bakery", label: "Bakery", isFixed: false },
  { value: "Sweets", label: "Sweets", isFixed: false },
  { value: "Candy", label: "Candy", isFixed: false },
  { value: "Snacks", label: "Snacks", isFixed: false },
  { value: "Food", label: "Food", isFixed: false },
];
const orderOptions = (values) => {
  if (values.length > 0)
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
};

const AnyReactComponent = ({ lat, lng }) => (
  <div>
    <Icon fontSize={24} style={{ cursor: "pointer" }} icon="ion:location" />
  </div>
);

export default function Addbusiness() {
  const [form, setform] = useState({ businessCategory: "" });
  const [fixedValue, setFixedValue] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showimg, setShowimg] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const handleShowimg = (imgUrl) => {
    console.log("imgUrl", imgUrl);
    setSelectedImg(imgUrl);
    setShowimg(true);
  };
  const handleCloseimg = () => {
    setSelectedImg(null);
    setShowimg(false);
  };
  const [subcategorylistArr, setsubcatglist] = useState([]);
  const queryClient = useQueryClient();
  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({}); // state for validation errors
  // useEffect(() => {
  //   console.log(validationErrors, "validationErrors==>");
  // }, [validationErrors]);
  const fixedOnChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue && removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = subcategory.filter((v) => v.isFixed);
        break;
      default:
        break;
    }

    value = orderOptions(value);
    setFixedValue(value);
  };
  const [locationName, setLocationName] = useState("");

  const [currentLat, setlat] = useState("10.99835602");
  const [currentLong, setlong] = useState("77.01502627");
  const [categoryId, setcategoryId] = useState(null);
  const navigate = useNavigate();
  const { businessCategory } = form;
  const url = `fetchBusinessCategory`;
  const {
    data: categorylist,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["categorylist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  const [previewImage, setpreviewImages] = useState({});
  const urlSub = `fetchBusinessSubCategory?categoryId=${businessCategory}`;

  const {
    data: subcategorylist,
    error: suberror,
    isLoading: subloading,
  } = useQuery({
    queryKey: ["subcatglisting", urlSub],
    queryFn: () => AuthgetApi(urlSub),
    staleTime: Infinity,
    enabled: !!businessCategory,
  });

  const [times, setBusinessHours] = useState([]);

  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const convertToFormData = (form) => {
    const formData = new FormData();

    for (const key in form) {
      if (Array.isArray(form[key])) {
        form[key].forEach((val) => {
          if (val instanceof File || val instanceof Blob) {
            formData.append(key, val);
          } else if (typeof val === "object" && val !== null) {
            formData.append(key, JSON.stringify(val));
          } else {
            formData.append(key, val);
          }
        });
      } else if (form[key] instanceof File || form[key] instanceof Blob) {
        formData.append(key, form[key]);
      } else if (typeof form[key] === "object" && form[key] !== null) {
        formData.append(key, JSON.stringify(form[key]));
      } else {
        formData.append(key, form[key]);
      }
    }

    return formData;
  };

  const mutation = useMutation({
    mutationFn: authFormdataPost,
    onSuccess: (data) => {
      // Store the token and fetch user details
      console.log(data, "is here --- >");
      if (data.status) {
        // const [form,setform]=useState({businessCategory:""})
        queryClient.invalidateQueries("businesslist"); // Invalidate and refetch admin data if you have a query for it
        setform({ businessCategory: "" });
        toast.success("Business added successfully!");
        navigate(-1);
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error("Login error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "businesslist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  const handleCheckboxChange = (day) => {
    setBusinessHours((prevState) => {
      const dayIndex = prevState.findIndex((item) => item.day === day);
      if (dayIndex > -1) {
        // If day is already in the array, remove it
        return prevState.filter((item) => item.day !== day);
      } else {
        // If day is not in the array, add it with default times
        return [...prevState, { day, openingHour: "", closingHour: "" }];
      }
    });
  };

  const handleTimeChange = (day, timeType, value) => {
    console.log("valuehandleTimeChange", day, timeType, value, times);

    // Get current state of the selected day
    const currentDayData = times.find((item) => item.day === day);
    if (!currentDayData) return;

    const [hours, minutes] = value.split(":").map(Number);

    const today = new Date(); // Get the current date
    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(0);
    today.setMilliseconds(0);

    // Set updated time in ISO format
    const updatedDateTime = today.toISOString();
    console.log("updatedDateTime", updatedDateTime);

    // If we're updating closing hour, ensure that opening hour is before it
    if (timeType === "closingHour") {
      if (
        currentDayData.openingHour &&
        new Date(currentDayData.openingHour) >= new Date(updatedDateTime)
      ) {
        alert("Closing hour must be after opening hour.");
        return; // Prevent updating closing hour if invalid
      }
    }

    // If we're updating opening hour, ensure that it's before closing hour
    if (timeType === "openingHour") {
      if (
        currentDayData.closingHour &&
        new Date(updatedDateTime) <= new Date(currentDayData.closingHour)
      ) {
        alert("Opening hour must be before closing hour.");
        return; // Prevent updating opening hour if invalid
      }
    }

    // If validation passes, update the business hours state
    setBusinessHours((prevState) =>
      prevState.map((item) =>
        item.day === day ? { ...item, [timeType]: updatedDateTime } : item
      )
    );
  };

  // const convertTimesObjectToArray = (timesObject) => {
  //   return Object.keys(timesObject).map(day => ({
  //     day,
  //     openingHour: timesObject[day].from || "",
  //     closingHour: timesObject[day].to || ""
  //   }));
  // };
  const transformTimesToObject = (times) => {
    return times.reduce((acc, curr) => {
      acc[curr.day] = {
        from: curr.openingHour,
        to: curr.closingHour,
      };
      return acc;
    }, {});
  };

  useEffect(() => {
    console.log(previewImage, "previewImage==>");
  }, [previewImage]);

  const imageHandling = (event, key) => {
    event.preventDefault();
    let previewURLs = [];
    let sendingData = [];
    let file = event.target.files;

    if (file?.length > 0) {
      Array.from(file).forEach((element) => {
        let previewUrl = URL.createObjectURL(element);
        previewURLs.push(previewUrl);
        sendingData.push(element);
      });

      setpreviewImages({ ...previewImage, [key]: previewURLs });
      setform({ ...form, [key]: sendingData });
    }
  };

  const removeImage = (index, key) => {
    const newPreviewImages = [...previewImage[key]];
    const newSendingData = [...form[key]];

    newPreviewImages.splice(index, 1);
    newSendingData.splice(index, 1);

    setpreviewImages((prevState) => ({
      ...prevState,
      [key]: newPreviewImages,
    }));
    setform((prevState) => ({ ...prevState, [key]: newSendingData }));
  };

  const toggleModal = () => {
    setShow(!show);
  };
  const defaultProps = {
    center: {
      lat: currentLat,
      lng: currentLong,
    },
    zoom: 8,
  };
  useEffect(() => {
    let arr = subcategorylist?.data;
    let customArr = [];
    arr?.forEach((element) => {
      // { value: 'Bakery', label: 'Bakery', isFixed: false },

      let obj = {};
      obj.value = element._id;
      obj.label = element.subCategory;
      obj.isFixed = false;
      customArr.push(obj);
    });
    setsubcatglist(customArr);
  }, [subcategorylist]);
  useEffect(() => {
    console.log(locationName, "locationName==>");
    // getLocationName(currentLat,currentLong)
  }, [currentLat, currentLong]);

  // const getLocationName = async (lat, lng) => {
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  //   );
  //   const data = await response.json();
  //   if (data.results && data.results[0]) {
  //     setLocationName(data.results[0].formatted_address);
  //   } else {
  //     setLocationName('Unknown location');
  //   }
  // };

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition((position) => {
        setlat(position.coords.latitude);
        setlong(position.coords.longitude);
        console.log(position.coords.latitude, position.coords.longitude);
      });
    } else {
      /* geolocation IS NOT available */
    }
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #DADADA",
      boxShadow: "none",
      minHeight: "52px",
      borderRadius: "8px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#000080", // Change background color of the selected value container
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
  };
  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#DADADA", // Custom border color
      boxShadow: state.isFocused ? "none" : "none",
      borderRadius: "8px", // Custom border radius
      height: "50px", // Increase the height of the input
      fontSize: "16px",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "14px",
      height: "100%", // Match the height to the control
    }),
    menu: (provided) => ({
      ...provided,
      borderColor: "#DADADA", // Custom border color for suggestions container
      borderRadius: "8px", // Custom border radius for suggestions container
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the separator
    }),
    dropdownIndicator: () => ({
      display: "none", // Hide the dropdown arrow
    }),
  };
  const { businessLogo } = previewImage;

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // console.log(times, "SDdsdsdsdsdsdsdsd");
    // return;
    let formData = {
      ...form,
      businessLocation: [currentLat, currentLong],
      times: JSON.stringify(transformTimesToObject(times)),
      businessSubCategory: fixedValue,
      // locationName:{label:""},
      locationName,
    };
    console.log("formData", formData);

    const { error, value } = businessValidationSchema.validate(formData, {
      abortEarly: false,
    });
    formData.locationName = JSON.stringify(formData.locationName);
    value.businessSubCategory = JSON.stringify(value?.businessSubCategory);
    console.log(error, "error is here checkout ", value.businessSubCategory);

    if (error) {
      const errors = {};
      error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setValidationErrors(errors);
    } else {
      setValidationErrors({});

      let formdata = new FormData();
      let parms = {
        url: "adminCreateBusiness",
        formdata: convertToFormData(value),
      };
      mutation.mutate(parms);
      // Submit the form
      // console.log('Form submitted successfully', values);
      // handle form submission logic here, like making an API call
      console.log("Form data is valid: ", value);
    }
  };

  const handleSelect = (place) => {
    console.log(place?.value?.description, "place.description===>");
    // setLocationName(place?.label)
    setLocationName(place); // Update locationName with the selected location's description

    setform({ ...form, businessLocation: place?.label });
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: place?.value?.place_id }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
        const location = results[0].geometry.location;
        // setCoordinates({
        //   lat: location.lat(),
        //   lng: location.lng(),
        // });
        setlat(location.lat());
        setlong(location.lng());
        // console.log(location.lat(),location.lng(),"location are here")
      }
    });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleToggle = (e) => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    console.log("handleToggleworking", isOpen, e.target.checked);

    setform({
      ...form,
      isOpen24Hours: e.target.checked,
      times: "",
    });
    console.log(form);
  };
  console.log("times", times);
  if (times.length > 0) {
    let openingHour =
      times.find((item) => item?.day === "mon")?.openingHour || "";
    openingHour = openingHour
      ? new Date(openingHour).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "";
    console.log("Opening Hour:", openingHour);
  }
  const handlePhoneChange = (value, country) => {
    console.log("value, country",value, country.countryCode,country.dialCode);
    // return;
    setform({
      ...form,
      businessPhoneNumber: value,
    });
    // setPhone(value); // Phone number with country code
    // setCountryData(country); // Contains country-related details
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add Business</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Business"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3 ">
              <div className="user_info text-center d-flex flex-column justify-content-center align-items-center">
                <img
                  src={businessLogo ?? Avatar}
                  className="rounded-circle img-fluid profilepic"
                  alt=""
                />
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Logo
                </label>
                <input
                  onChange={(e) => imageHandling(e, "businessLogo")}
                  type="file"
                  accept="image/*"
                  id="uploadprofileimg"
                  className="d-none"
                />

                {validationErrors?.businessLogo && (
                  <p className="text-danger">
                    {validationErrors?.businessLogo}
                  </p>
                )}
              </div>
              {/* {validationErrors.businessLogo && <p className="text-danger">{validationErrors.businessLogo}</p>} */}
            </Col>
            <Col md={7} xl={8}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={form?.fullName}
                        onChange={(e) =>
                          setform({ ...form, fullName: e.target.value })
                        }
                      />
                      {validationErrors?.fullName && (
                        <p className="text-danger">
                          {validationErrors?.fullName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={form?.email}
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                      />
                      {validationErrors?.email && (
                        <p className="text-danger">{validationErrors?.email}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <PhoneInput
                        country={"us"}
                        value={form?.businessPhoneNumber}
                        enableSearch
                        // onChange={handlePhoneChange}
                        onChange={(value) =>
                          setform({
                            ...form,
                            businessPhoneNumber: value,
                          })
                        }
                        inputStyle={{ width: "100%" , paddingLeft:"50px" }}
                      />
                      {/* <Form.Label>Phone No.</Form.Label> */}
                      {/* <Form.Control
                        type="text"
                        placeholder="Enter phone no."
                        value={form?.businessPhoneNumber}
                        onChange={(e) =>
                          setform({
                            ...form,
                            businessPhoneNumber: e.target.value,
                          })
                        }
                      />
                      {validationErrors?.businessPhoneNumber && (
                        <p className="text-danger">
                          {validationErrors?.businessPhoneNumber}
                        </p>
                      )} */}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className="form-group mb-3"
                      style={{ position: "relative", height: "100px" }}
                    >
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        value={form?.password}
                        onChange={(e) =>
                          setform({ ...form, password: e.target.value })
                        }
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                      {validationErrors?.password && (
                        <p className="text-danger">
                          {validationErrors?.password}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  {/* <Col lg={6}>
                                    <GooglePlacesAutocomplete
                                        apiKey={GOOGLE_API_KEY}
                                      />
                                    </Col> */}
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Business Name"
                        value={form?.businessName}
                        onChange={(e) =>
                          setform({ ...form, businessName: e.target.value })
                        }
                      />
                      {validationErrors?.businessName && (
                        <p className="text-danger">
                          {validationErrors?.businessName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Location</Form.Label>
                      <div className="position-relative">
                        <span className="location_icon" onClick={handleShow}>
                          <Icon
                            style={{ cursor: "pointer" }}
                            icon="ion:location"
                          />
                        </span>
                        {/* <Form.Control disabled className="pe-5" type="text" placeholder="Enter Location" value={locationName} onChange={(e) => setform({...form,businessLocation:e.target.value})}/> */}
                        <GooglePlacesAutocomplete
                          apiKey={GOOGLE_API_KEY}
                          selectProps={{
                            value: locationName,
                            styles: customStyles2,
                            placeholder: "Enter a location",
                            onChange: handleSelect,
                          }}
                        />
                      </div>
                      {validationErrors?.locationName && (
                        <p className="text-danger">
                          {validationErrors?.locationName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        aria-label="Select Category"
                        value={form?.businessCategory}
                        onChange={(e) =>
                          setform({ ...form, businessCategory: e.target.value })
                        }
                      >
                        <option value="select">Select Business</option>
                        {categorylist?.data?.map((val, ind) => {
                          return (
                            <option key={ind} value={val?._id}>
                              {val?.category}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {validationErrors?.businessCategory && (
                        <p className="text-danger">
                          {validationErrors?.businessCategory}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Subcategory</Form.Label>
                      <Select
                        isClearable={false}
                        value={fixedValue}
                        isMulti
                        name="colors"
                        styles={customStyles}
                        options={subcategorylistArr}
                        onChange={fixedOnChange}
                        className="multiselect"
                        classNamePrefix="select"
                      />
                      {validationErrors?.businessSubCategory && (
                        <p className="text-danger">
                          {validationErrors?.businessSubCategory}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Plan</Form.Label>
                      <Form.Select
                        aria-label="Select Category"
                        value={form?.plan}
                        onChange={(e) =>
                          setform({ ...form, plan: e.target.value })
                        }
                      >
                        <option value="select">Select Plan</option>
                        {/* {
                                                    categorylist?.data?.map((val,ind)=>{
                                                <option value={val?._id}>{val?.category}</option>
                                                    })
                                                } */}

                        <option value="1">Free</option>
                        <option value="2">Basic</option>
                        <option value="3">Standard</option>
                        <option value="4">Premium</option>
                      </Form.Select>
                      {validationErrors?.plan && (
                        <p className="text-danger">{validationErrors?.plan}</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Whatsapp Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Whatsapp Link"
                        value={form?.whatsappLink}
                        onChange={(e) =>
                          setform({ ...form, whatsappLink: e.target.value })
                        }
                      />
                      {validationErrors?.whatsappLink && (
                        <p className="text-danger">
                          {validationErrors?.whatsappLink}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Instagram Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Instagram Link"
                        value={form?.instagramLink}
                        onChange={(e) =>
                          setform({ ...form, instagramLink: e.target.value })
                        }
                      />
                      {validationErrors?.instagramLink && (
                        <p className="text-danger">
                          {validationErrors?.instagramLink}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Telegram Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Telegram Link"
                        value={form?.telegramLink}
                        onChange={(e) =>
                          setform({ ...form, telegramLink: e.target.value })
                        }
                      />
                      {validationErrors?.telegramLink && (
                        <p className="text-danger">
                          {validationErrors?.telegramLink}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Is open 24*7</Form.Label>
                      <Form.Check
                        type="switch"
                        id="isOpenSwitch"
                        label={isOpen ? "Open" : "Closed"}
                        checked={isOpen}
                        onChange={handleToggle}
                      />
                    </Form.Group>
                  </Col>
                  {!isOpen ? (
                    <Col lg={12}>
                      <Form.Group className="form-group mb-3 timesetting_div">
                        <Form.Label>Select Business Hours</Form.Label>
                        {daysOfWeek.map((day) => (
                          <Form.Group key={day} className="form-check mb-3">
                            <Form.Check
                              type="checkbox"
                              onChange={() => handleCheckboxChange(day)}
                              checked={times.some((item) => item.day === day)}
                            />
                            <Form.Label check>
                              <span className="day_badge">{day}</span>
                              From
                              <Form.Control
                                type="time"
                                value={
                                  times.find((item) => item.day === day)
                                    ? new Date(
                                        times.find(
                                          (item) => item.day === day
                                        ).openingHour
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })
                                    : ""
                                }
                                onChange={(e) =>
                                  handleTimeChange(
                                    day,
                                    "openingHour",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  !times.some((item) => item.day === day)
                                }
                              />
                              To
                              <Form.Control
                                type="time"
                                value={
                                  times.find((item) => item.day === day)
                                    ? new Date(
                                        times.find(
                                          (item) => item.day === day
                                        ).closingHour
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })
                                    : ""
                                }
                                onChange={(e) =>
                                  handleTimeChange(
                                    day,
                                    "closingHour",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  !times.some((item) => item.day === day)
                                }
                              />
                            </Form.Label>
                            {validationErrors?.times && (
                              <p className="text-danger">
                                {validationErrors?.times}
                              </p>
                            )}
                          </Form.Group>
                        ))}
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Business Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Business Description"
                        value={form?.businessDescription}
                        onChange={(e) =>
                          setform({
                            ...form,
                            businessDescription: e.target.value,
                          })
                        }
                      />
                      {validationErrors?.businessDescription && (
                        <p className="text-danger">
                          {validationErrors?.businessDescription}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Upload Certificate</Form.Label>
                      <Form.Control
                        multiple
                        onChange={(e) =>
                          imageHandling(e, "businessCertificate")
                        }
                        id="categoryimg"
                        name="categoryimg"
                        className="displaynone"
                        type="file"
                      />
                      <Form.Label className="fileupload" htmlFor="categoryimg">
                        <Icon
                          className="uplaodicon"
                          icon="ion:camera-outline"
                        ></Icon>
                        <h3>Upload Certificate Here</h3>
                      </Form.Label>
                      {validationErrors?.businessCertificate && (
                        <p className="text-danger">
                          {validationErrors?.businessCertificate}
                        </p>
                      )}
                    </Form.Group>
                    {previewImage["businessCertificate"]?.map((val, ind) => {
                      return (
                        <>
                          <img
                            src={val}
                            key={ind}
                            alt={`Preview ${ind}`}
                            style={{
                              width: "70px",
                              height: "60px",
                              margin: "10px",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              // console.log(" working ");
                              handleShowimg(`${val}`);
                            }}
                          />
                          <span
                            onClick={() =>
                              removeImage(ind, "businessCertificate")
                            }
                            style={{
                              color: "white",
                              position: "absolute",
                              marginLeft: "-20px",
                              border: "0.4px solid",
                              borderRadius: "180px",
                              background: "darkred",
                              fontWeight: "bold",
                              display: "inline-flex", // Ensures the icon is centered
                              alignItems: "center",
                              justifyContent: "center",
                              width: "20px", // Ensure the size of the container
                              height: "20px", // Ensure the size of the container
                              cursor: "pointer",
                            }}
                          >
                            <Icon icon="tabler:x" />
                          </span>
                        </>
                      );
                    })}
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Business Photos</Form.Label>
                      <Form.Control
                        onChange={(e) => imageHandling(e, "businessPic")}
                        id="businessImage"
                        name="businessImage"
                        className="displaynone"
                        multiple={true}
                        type="file"
                        accept="image/*"
                      />
                      <Form.Label
                        className="fileupload"
                        htmlFor="businessImage"
                      >
                        <Icon
                          className="uplaodicon"
                          icon="ion:camera-outline"
                        ></Icon>
                        <h3>Drag & Drop Here</h3>
                      </Form.Label>
                      {validationErrors?.businessPic && (
                        <p className="text-danger">
                          {validationErrors?.businessPic}
                        </p>
                      )}
                    </Form.Group>
                    {previewImage["businessPic"]?.map((val, ind) => {
                      return (
                        <>
                          <img
                            src={val}
                            key={ind}
                            alt={`Preview ${ind}`}
                            style={{
                              width: "70px",
                              height: "60px",
                              margin: "10px",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              // console.log(" working ");
                              handleShowimg(`${val}`);
                            }}
                          />
                          <span
                            onClick={() => removeImage(ind, "businessPic")}
                            style={{
                              color: "white",
                              position: "absolute",
                              marginLeft: "-20px",
                              border: "0.4px solid",
                              borderRadius: "180px",
                              background: "darkred",
                              fontWeight: "bold",
                              display: "inline-flex", // Ensures the icon is centered
                              alignItems: "center",
                              justifyContent: "center",
                              width: "20px", // Ensure the size of the container
                              height: "20px", // Ensure the size of the container
                              cursor: "pointer",
                            }}
                          >
                            <Icon icon="tabler:x" />
                          </span>
                        </>
                      );
                    })}
                  </Col>
                </Row>
                <Button
                  onClick={handleFormSubmit}
                  className="btn btn-primary px-4 mt-3 min_width140"
                >
                  {" "}
                  {mutation.isPending ? (
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="100"
                      color="white"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          {/* <Link   onClick={handleClose} className="modalclose_btn"><Icon icon="ic:outline-close" /></Link> */}
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                position: "relative",
                width: "80%",
                height: "80%",
                minWidth: "400px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key:
                    process.env.GOOGLE_MAP_API_KE ??
                    "AIzaSyBHQWCrTWJIMYz04ZN43VyZ9xv6agXnuyk",
                }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                onClick={(event) => {
                  console.log(event, "console events");
                  setlat(event?.lat);
                  setlong(event?.lng);
                  // getLocationName(event?.lat, event?.lng);
                }}
              >
                <AnyReactComponent lat={currentLat} lng={currentLong} />
              </GoogleMapReact>

              <button
                onClick={toggleModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  fontSize: "30px",
                  cursor: "pointer",
                  borderWidth: 1,
                }}
              >
                &times;
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="modalimg_div"
        show={showimg}
        onHide={handleClose}
        centered
      >
        <button className="remove-icon" onClick={handleCloseimg}>
          &times;
        </button>
        <Modal.Body>
          <img src={selectedImg} alt="" />
        </Modal.Body>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}
