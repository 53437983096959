import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ThreeCircles } from "react-loader-spinner";

// import validationSchema from "../formValidation/loginSchema";
import { useMutation } from "@tanstack/react-query";
import { loginUser } from "../axiosConfig/api";
import Cookies from "js-cookie"; // Import Cookies from js-cookie package
import validationSchema from "../formValidation/loginSchema";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let token = Cookies.get("token");
    if (token) {
      navigate("/Dashboard");
    }
  }, [navigate]);

  const [errors, setErrors] = useState({});

  const mutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        toast.success("Logged in successfully!");
        Cookies.set("token", data?.data?.userToken);
        navigate("Dashboard");
        return;
      }
      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error("Login error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "login", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validate = async () => {
    const { error } = validationSchema.validate(values, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    console.log("woring");
    e.preventDefault();
    const formErrors = await validate();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      let parms = {
        url: "adminlogin",
        email: values?.email,
        password: values?.password,
      };
      mutation.mutate(parms);
      // Submit the form
      // console.log('Form submitted successfully', values);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Welcome to adaap</h2>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter email address"
                />
                {errors.email && (
                  <p style={{ fontSize: 12, color: "red" }}>{errors.email}</p>
                )}
              </Form.Group>
              <Form.Group
                className="mb-4 form-group"
                style={{ position: "relative", height: "100px" }}
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Enter password"
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
                {errors.password && (
                  <p style={{ fontSize: 12, color: "red" }}>{errors.password}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-4 form-group forgotpassword text-end">
                <Link to="/ForgotPassword">Forgot Password?</Link>
              </Form.Group>
              <Link to="#">
                <Button
                  onClick={handleSubmit}
                  className="btn btn-primary w-100"
                >
                  {mutation.isPending ? (
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="100"
                      color="white"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "LogIn"
                  )}
                </Button>
              </Link>{" "}
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
      <Toaster />
    </React.Fragment>
  );
}
